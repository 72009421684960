export default function () {
  function SignIn() {
    window.gapi.auth2.getAuthInstance().signIn();
  }

  function SignOut() {
    window.gapi.auth2.getAuthInstance().signOut();
  }

  return {
    SignIn,
    SignOut,
  };
}
