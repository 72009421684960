<template>
  <div
    class="fullscreen bg-blue text-white text-center q-pa-md flex flex-center"
  >
    <div>
      <div class="text-h1 q-mb-md">Budget Tracker</div>

      <div class="text-h4" style="opacity: 0.4">Please sign in to use the application</div>

      <q-btn
        class="q-mt-xl"
        color="white"
        text-color="blue"
        unelevated
        label="Sign In"
        no-caps
        @click="SignIn"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import auth from "../mixins/auth";

export default defineComponent({
  name: "Unauthorized",
  setup() {
    const store = useStore();
    const router = useRouter();
    const { SignIn } = auth();
    const signedIn = computed(() => store.getters["SignedIn"]);

    watch(signedIn, () => {
      if (signedIn.value) {
        router.push({ name: "Dashboard" });
      }
    });

    onMounted(() => {
      console.log("unauthorized:mounted");
    });

    return {
      signedIn,
      SignIn,
    };
  },
});
</script>
